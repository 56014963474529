<template>
  <div
    :class="{
      'waring-list-warp': true,
      'four-style': +systemConfig.theme_type === 4,
    }"
    @scroll="scrollEvent"
    v-loading="loading"
  >
    <div
      class="list-item"
      v-for="(item, index) in list"
      :key="index"
      @click="handleItemClick(item)"
    >
      <div class="title-warp">
        <span class="status" :class="flterStatus(item.warn_level).class">{{
          flterStatus(item.warn_level).text
        }}</span>
        <div class="line-title">
          <span class="title"
            >【{{ item.warn_type || '其他' }}预警】{{ item.warn_content }}</span
          >
        </div>
      </div>
      <div class="info-warp">
        <span class="project-info"
          >项目：{{ item.current_name ? item.current_name : '-' }}</span
        >
        <span class="time">{{ filterTime(item.created_at) }}</span>
      </div>
    </div>
    <Empty
      v-if="list.length === 0"
      description="暂无数据"
      :image-size="100"
    ></Empty>
    <!-- <alertDetailPop
      class="warning-detail"
      v-if="warringData"
      :item="warringData"
      @close-pop="warringData = false"
    /> -->
    <div class="alert-dialog">
      <alertDetailPop
        class="warning-detail"
        v-if="dialogVisible"
        :item="warringData"
        @changeItemDetail="changeItemDetail"
        @close-pop="dialogVisible = false"
      />
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns';
import { Empty } from 'element-ui';
import { dataInterface } from '@/apis/data';
// import WarringDialog from './WarringDialog.vue';
import alertDetailPop from '../../../todo/components/rightCont/alertNotify/alertDetailPop.vue';
import { mapState } from 'vuex';
export default {
  components: {
    Empty,
    // WarringDialog,
    alertDetailPop,
  },
  computed: {
    ...mapState(['systemConfig']),
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      warringData: {},
      dialogVisible: false,
    };
  },
  methods: {
    onClose() {
      this.dialogVisible = false;
    },
    changeItemDetail() {
      dataInterface({},'api/graph/1288').then(res => {
          if (res.data.code === 200) {
              res.data.data.forEach(el => {
                  if(this.warringData.id===el.id){
                      this.warringData=el
                  }
              })
          }

      })
    },
    onSave() {
      this.dialogVisible = false;
      this.$emit('refresh');
    },
    handleItemClick(item) {
      this.dialogVisible = true;
      this.warringData = item;
    },
    flterStatus(text = '') {
      if (text.length === 0) {
        return {
          text: '-',
          class: '',
        };
      }
      /* 增加判断紫色预警，紫色预警为智慧工地相关，没有颜色但是有类型的情况 */
      const str = text[0];
      const classHash = {
        黄: 'y-status',
        橙: 'o-status',
        蓝: 'b-status',
        红: 'r-status',
      };
      return {
        text: classHash[str] ? `${str}色预警` : str ? '紫色预警' : '其他预警',
        class: classHash[str]
          ? classHash[str]
          : str
          ? 'p-status'
          : 'other-status',
      };
    },
    filterTime(time) {
      const targetDate = new Date(time);
      if (isNaN(targetDate.getTime())) {
        return '';
      } else {
        return this.timeFormat(time);
      }
    },
    // 时间格式化
    timeFormat(time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      if (ny === ty) {
        if (nm === tm) {
          return `${format(targetDate, 'MM/dd HH:mm')}`;
        } else {
          return `${format(targetDate, 'MM/dd HH:mm')}`;
        }
      } else {
        return `${format(targetDate, 'yyyy/MM/dd')}`;
      }
    },
    /**
     * @desc: 滚动加载
     * @param {Object} e
     */
    scrollEvent(e) {
      this.$emit('send-scroll-event', e);
    },
  },
  mounted() {
    const floatingElement = document.querySelector('.alert-dialog');
    document.body.appendChild(floatingElement);
  },
  provide() {
    return {
      toUpdate: (p1, p2) => {
        this.$emit('refresh');
      },
    };
  },
};
</script>
<style lang="less" scoped>
.waring-list-warp {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .list-item {
    width: 100%;
    padding: 12px 10px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
      border-radius: 3px;
    }
    .title-warp {
      width: 100%;
      display: flex;
      align-items: center;
      .line-title {
        flex: 1;
        overflow: hidden;
        position: relative;
        height: 22px;
        margin-right: 8px;
      }
      .status {
        height: 18px;
        border-radius: 2px;
        padding: 0 6px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: YouSheBiaoTiHei;
        line-height: 18px;
        -webkit-text-stroke: 0px #ffeecc;
      }
      .r-status {
        background: #ffe6e6;
        color: #ff5c5c;
      }
      .o-status {
        background: #ffe7d4;
        color: #ff8826;
      }
      .b-status {
        background: #e0e8ff;
        color: #5c87ff;
      }
      .y-status {
        background: hsla(40, 100%, 50%, 0.2);
        color: #ffaa00;
      }
      .other-status {
        background: rgba(77, 77, 77, 0.2);
        color: #777;
      }
      .p-status {
        background: #f8e0ff;
        color: #ca64ea;
      }
      .title {
        margin-left: 2px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2a2f3d;
        line-height: 22px;
        display: inline-block;
        position: absolute;
        left: 0px;
        &:hover {
          animation: 5s todayScroll linear infinite;
        }
      }
      .is-readed {
        color: #6d768f;
      }
    }
    .info-warp {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .project-info {
        height: 18px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6d768f;
        line-height: 18px;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time {
        height: 18px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6d768f;
        line-height: 18px;
      }
    }
  }
}
@keyframes todayScroll {
  0% {
    left: 0%;
  }

  100% {
    left: -35%;
  }
}
.four-style {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .list-item {
    display: flex;
    padding: var(--spacing-6, 12px) 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-3, 6px);
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #f9f6f4;
    background: var(--overall-surface-default, #fff);
    &:hover {
      border-radius: 12px;
      border: 1px solid #f9f6f4;
      background: linear-gradient(
        180deg,
        rgba(247, 171, 25, 0) 2.19%,
        rgba(242, 119, 19, 0.1) 52.39%,
        rgba(231, 0, 6, 0.1) 98.44%
      );
    }
    .title-warp {
      .status {
        display: flex;
        height: 20px;
        padding: 0px var(--spacing-3, 6px);
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-2, 4px);

        text-align: center;

        /* web/cn/helper/helper-02 */
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: 0.4px;
      }
    }
    .line-title {
      .title {
        overflow: hidden;
        color: var(--text-on-surface-primary, #181b22);
        text-overflow: ellipsis;

        /* web/cn/helper/helper-03 */
        font-family: 'PingFang SC';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        letter-spacing: 0.4px;
      }
    }
    .info-warp {
      width: 100%;
      .project-info,
      .time {
        color: var(--text-on-surface-placeholder, #a4acbd);

        /* web/cn/helper/helper-02 */
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: 0.4px;
      }
    }
  }
}
.warning-detail {
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.alert-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
